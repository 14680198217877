.Main {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Content {
	height: fit-content;
	width: fit-content;
}

.Frame {
	height: 700px;
	width: 600px;
	border: 1px solid #707070;
}

.PCI_ThreeDS {
	height: 62px;
	width: 62px;
	border: none;
}

@media screen and (max-width: 1110px) {
	.Frame {
		height: 700px;
		width: 90vw;
	}
}

@media screen and (max-width: 760px) {
	.Frame {
		height: 700px;
		width: 90vw;
	}
}

@media screen and (max-width: 630px) {
	.Frame {
		height: 550px;
		width: 95vw;
	}
}

@media screen and (max-width: 338px) {
	.Frame {
		height: 700px;
		width: 100vw;
	}
}
