.Panel_panel_container {
	padding: 60px 90px 30px 90px;
	background-color: #fff;
	border-radius: 16px;
	width: 700px;
	margin: 40px auto 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Panel_panel_container .ui_subtitle {
	color: #8b8b87;
	font-size: 20px;
	font-weight: 500;
	padding: 0px 0px 10px 0px;
}
@media (max-width: 768px) {
	.Panel_panel_container {
		padding: 20px 16px;
		width: 320px;
	}
	.Panel_panel_container h1 {
		text-align: center;
		font-size: 24px;
	}
}
.RadioBoxOption_radiobox_container {
	-webkit-box-shadow: 0 5px 20px rgba(26, 26, 24, 0.1);
	box-shadow: 0 5px 20px rgba(29, 29, 27, 0.1);
	border-radius: 16px;
	background-color: #fff;
	padding: 16px;
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	width: 340px;
	justify-content: space-between;
}

.RadioBoxOption_radiobox_container_gpay {

	margin-top: 16px;
	display: flex;
	flex-direction: row;
	width: 340px;
	justify-content: space-between;
	margin-bottom: 16px;
}

@media (max-width: 768px) {
	.RadioBoxOption_radiobox_container {
		width: 100%;
	}
	.RadioBoxOption_radiobox_container img {
		margin-right: 8px;
	}

	.RadioBoxOption_radiobox_container_gpay {
		width: 100%;

	}
}
.RadioBoxOption_radiobox_container:not(:last-child) {
	margin-bottom: 20px;
}

.RadioBoxOption_radiobox_container .RadioBoxOption_detail_image {
	margin-top: 8px;
}

.RadioBoxOption_radiobox_container img {
	vertical-align: top;
}

.RadioBoxOption_radiobox_container .RadioBoxOption_detail_container {
	width: 200px;
}
.RadioBoxOption_radiobox_container .RadioBoxOption_detail_container h4 {
	margin-top: 5px;
	margin-bottom: 5px;
	text-align: left;
}
.RadioBoxOption_detail_container {
	pointer-events: none;
}
.RadioBoxOption_radiobox_container .RadioBoxOption_detail_container p {
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 400;
	color: #60605E;
	text-align: left;
}

.RadioBoxOption_radiobox_container .RadioBoxOption_radio_custom_container {
	display: flex;
	align-items: center;
}
.RadioBoxOption_radiobox_container .RadioBoxOption_radio_custom {
	width: 32px;
	height: 32px;
	background-image: url(../../../assets/images/check-open.svg);
	background-repeat: no-repeat;
}

.RadioBoxOption_radiobox_container:hover {
	background-color: #ffcd00;
	cursor: pointer;
}

.RadioBoxOption_radiobox_container.disabled {
	background-color: #f7f7f7;
	cursor: not-allowed;
	color: #d1d1d1;
	text-decoration: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.RadioBoxOption_radiobox_container:hover .RadioBoxOption_radio_custom {
	background-image: url(../../../assets/images/check-hover.svg);
}

.RadioBoxOption_radiobox_container:hover .RadioBoxOption_radio_custom.disabled {
	background-image: url(../../../assets/images/check-open.svg);
}

.RadioBoxOption_radiobox_container.RadioBoxOption_selected {
	background-color: #1d1d1b;
}
.RadioBoxOption_radiobox_container.RadioBoxOption_selected img {
	-webkit-filter: invert(1);
	filter: invert(1);
}
.RadioBoxOption_radiobox_container.RadioBoxOption_selected .RadioBoxOption_radio_custom {
	background-image: url(../../../assets/images/check-selected.svg);
}
.RadioBoxOption_radiobox_container.RadioBoxOption_selected .RadioBoxOption_detail_container h4,
.RadioBoxOption_radiobox_container.RadioBoxOption_selected .RadioBoxOption_detail_container p {
	color: #fff;
}
.ButtonBlock_buttonBlock {
	display: flex;
	align-items: center;
	width: 340px;
	justify-content: space-between;
}
@media (max-width: 768px) {
	.ButtonBlock_buttonBlock {
		flex-direction: column;
		width: 100%;
	}
	.ui_commons_h1 {
		font-size: 22px !important;
	}
	.Panel_panel_container h3 {
		font-size: 18px;
	}
	.Panel_panel_container .ui_subtitle {
		color: #8b8b87;
		font-weight: bold;
		font-size: 16px;
	}
}
.ui_commons_h1 {
	color: #1D1D1B;
	font-size: 28px;
	font-weight: 700;
	padding: 0px 0px 10px 0px;
}
.ui_commons_h4 {
	color: #3f3e38;
	font-size: 16px;
	font-weight: 400;
}
.ui_commons {
	font-size: 14px;
	color: #60605e;
}
.ui_commons_ikbtn {
	color: #3f3e38;
	padding: 14px 18px;
	border-radius: 16px;
	background: none;
	border: none;
	width: 100%;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	min-width: 160px;
}
.ui_commons_ikbtn:hover {
	cursor: pointer;
}
.ui_commons_ikbtn.ui_commons_primary {
	background-color: #ffcd00;
	border: 2px solid #ffcd00;
}
.ui_commons_ikbtn.ui_commons_primary:hover {
	background-color: #1d1d1b;
	color: #fff;
	border-color: #1d1d1b;
}
.ui_commons_ikbtn.ui_commons_primary:disabled {
	cursor: not-allowed;
	background-color: #e8e8e7;
	color: #bababa;
	border-color: #e8e8e7;
}
.ui_commons_ikbtn.ui_commons_secondary {
	background-color: #fff;
	border: 2px solid #3f3e38;
}
@media (max-width: 768px) {
	.ui_commons_ikbtn {
		min-width: 100%;
	}
	.ui_commons_ikbtn:not(last-child) {
		margin-bottom: 16px;
	}
}
